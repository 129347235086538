import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import { BootstrapVue3 } from 'bootstrap-vue-3';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { getCookie, setCookie, generateRandomUserId } from './utils/cookies';

library.add(faFacebookF, faInstagram, faLinkedin, faTwitter );


//now
const app = createApp(App);

//Cookie management OLD
const COOKIE_NAME = 'userId';

let userId = getCookie(COOKIE_NAME);


if (!userId) {
  userId = generateRandomUserId();
  setCookie(COOKIE_NAME, userId, { expires: 365, secure: true, sameSite: 'Lax', domain: 'fitmithanno.fun' }); // Setze Cookie für 1 Jahr
}

//Cookie new
//const COOKIE_NAME = 'userId';

// Setze ein Cookie
// this.$cookie.set('userId', '12345', { expires: 365, secure: true, sameSite: 'Lax' });
// const userId = this.$cookie.get('userId');

// function setCookie(name, value, days, options = {}) {
//     const { secure = true, sameSite = 'Lax' } = options;
//     let expires = "";

//     if (days) {
//         const date = new Date();
//         date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Ablaufzeit
//         expires = "; expires=" + date.toUTCString();
//     }

//     document.cookie = `${name}=${value || ""}${expires}; path=/` +
//                       (secure ? "; Secure" : "") + 
//                       `; SameSite=${sameSite}`;
// }

// Lese ein Cookie
// function getCookie(name) {
//     const nameEQ = name + "=";
//     const cookies = document.cookie.split(';');
//     for (let i = 0; i < cookies.length; i++) {
//         let c = cookies[i];
//         while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//         if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//     }
//     return null;
// }

// Lösche ein Cookie
// function deleteCookie(name) {
//     document.cookie = `${name}=; Max-Age=0; path=/;`;
// }

// Globale Cookie-Nutzung
// let userId = getCookie(COOKIE_NAME);
// if (!userId) {
//     userId = Math.random().toString(36).substring(2, 15);
//     setCookie(COOKIE_NAME, userId, 365); // Cookie für 1 Jahr setzen
//}


// Set global property
//app.config.globalProperties.$userId = 12312312; // Example User ID
app.config.globalProperties.$userId = userId;

//global property for backend link
const isProduction = process.env.NODE_ENV === 'production';
//const hostname = window.location.hostname;
let backendLink;

if (isProduction) {
  backendLink = 'https://www.fitmithanno.fun/api';
  //backendLink = 'https://playground.fitmithanno.fun/api';
  
  // Handle production cases
  // if (hostname === 'test.fitmithanno.fun') {
  //   backendLink = 'https://test.fitmithanno.fun/api';
  // } else if (hostname === 'www.fitmithanno.fun') {
  //   backendLink = 'https://www.fitmithanno.fun/api';
  // } else {
  //   // Default to a fallback URL if the subdomain is unexpected
  //   backendLink = 'https://www.fitmithanno.fun/api';
  // }
} 
else {
  // For development, always use localhost API
  backendLink = 'http://localhost/api';
}

app.config.globalProperties.$backendlink = backendLink;



app.use(router);
app.use(bootstrap);
app.use(BootstrapVue3);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
